@mixin flexBox() {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin font($font, $fontSize, $lineHeight, $color) {
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  font-size: $fontSize;
  line-height: $lineHeight;
  color: $color;
}
.slick-list {
  height: 260px;
  padding-top: 30px !important;
  &:hover {
    & > div {
      // transition: none !important;
      // pointer-events: auto !important;
    }
  }
}
.cc {
  width: 370px !important;
  height: 171px;
  border: 1.5px solid #eef0f4;
  border-radius: 10px;
  padding: 36px 30px 33px 35px;
  cursor: pointer;
  margin-right: 20px;
  & .bubble {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    margin-bottom: 28px;
    @include flexBox();
    img {
      width: 24px;
      height: 24px;
    }
  }
  &.active {
    box-shadow: 0px 14px 53px rgba(0, 0, 0, 0.1);
    border: none;
  }
  &.index1,
  &.index6 {
    .bubble {
      background: #2eacfc;
      img {
        width: 28px;
        height: 28px;
      }
    }
    &.active {
      border-bottom: 4px solid #2eacfc;
    }
  }
  &.index2,
  &.index7 {
    .bubble {
      background: #ff9444;
    }
    &.active {
      border-bottom: 4px solid #ff9444;
    }
  }
  &.index3,
  &.index8 {
    .bubble {
      background: #28fa91;
    }
    &.active {
      border-bottom: 4px solid #28fa91;
    }
  }
  &.index4,
  &.index9 {
    .bubble {
      background: #34113d;
    }
    &.active {
      border-bottom: 4px solid #34113d;
    }
  }
  &.index5,
  &.index10 {
    .bubble {
      background: #ff3a89;
    }
    &.active {
      border-bottom: 4px solid #ff3a89;
    }
  }
  h5 {
    @include font("Inter", 20px, 24px, #000);
    font-weight: 500;
  }
}
.slick-slide {
  // width: unset !important;
}
// .slick-prev {
//   display: block;
//   background: green !important;
//   z-index: 10000;
//   position: fixed;
//   left: 20px !important;
// }

.active1 {
  & > div {
    background: #45e093 !important;
  }
}

.active2 {
  & > div {
    background: #58d8ff !important;
  }
}

.active3 {
  & > div {
    background: #ff9444 !important;
  }
}
@media screen and (max-width: 1200px) {
  .cc {
    width: 330px !important;
  }
}
@media screen and (max-width: 800px) {
  .cc {
    width: 290px !important;
    height: 160px;
    padding: 27px 24px 20px 27px;
    h5 {
      font-size: 19px;
    }
  }
}
@media screen and (max-width: 600px) {
  .cc {
    width: 268px !important;
    height: 151px;
    padding: 22px 24px 20px 18px;
    h5 {
      font-size: 18px;
    }
  }
}
