@mixin flexBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  .loader {
    @include flexBox();
    & > span {
      display: inline-block;
      height: 25px;
      width: 25px;
      border-radius: 0px;
    }
    & > span:nth-child(1) {
      border-radius: 500px;
      background: #2eacfc;
      -webkit-animation: push 1s 0.05s infinite
        cubic-bezier(0.005, 0.56, 0.58, 1.59);
      animation: push 1s 0.05s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    }
    & > span:nth-child(2) {
      border-radius: 500px;
      background: #ff9444;
      -webkit-animation: push 1s 0.1s infinite
        cubic-bezier(0.005, 0.56, 0.58, 1.59);
      animation: push 1s 0.1s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    }
    & > span:nth-child(3) {
      border-radius: 500px;
      background: #28fa91;
      -webkit-animation: push 1s 0.15s infinite
        cubic-bezier(0.005, 0.56, 0.58, 1.59);
      animation: push 1s 0.15s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    }
    & > span:nth-child(4) {
      border-radius: 500px;
      background: #34113d;
      -webkit-animation: push 1s 0.2s infinite
        cubic-bezier(0.005, 0.56, 0.58, 1.59);
      animation: push 1s 0.2s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    }
    & > span:nth-child(5) {
      border-radius: 500px;
      background: #ff3a89;
      -webkit-animation: push 1s 0.25s infinite
        cubic-bezier(0.005, 0.56, 0.58, 1.59);
      animation: push 1s 0.25s infinite cubic-bezier(0.005, 0.56, 0.58, 1.59);
    }
  }
}
@-webkit-keyframes push {
  0% {
    transform: translateX(0px) scale(0.9, 0.6);
  }
  // 20% {
  //   background: #ff3a89;
  // }
  // 40% {
  //   background: #2eacfc;
  // }
  50% {
    transform: translateY(-20px) scale(0.7, 1.1);
    // background: #6bd6c8;
  }
  // 60% {
  //   background: #ff9444;
  // }
  // 80% {
  //   background: #34113d;
  // }
  100% {
    transform: translateX(0px) scale(0.9, 0.6);
  }
}
@keyframes push {
  0% {
    transform: translateX(0px) scale(0.9, 0.6);
  }
  // 20% {
  //   background: #ff3a89;
  // }
  // 40% {
  //   background: #2eacfc;
  // }
  50% {
    transform: translateY(-20px) scale(0.7, 1.1);
    // background: #6bd6c8;
  }
  // 60% {
  //   background: #ff9444;
  // }
  // 80% {
  //   background: #34113d;
  // }
  100% {
    transform: translateX(0px) scale(0.9, 0.6);
  }
}
